<template>
  <div class="col-12">
    <h2>تقارير البيانات التراكمية حتى تاريخ
        {{ date }}
    </h2>

    <table>
        <tr>
            <th class="r">#</th>
            <th class="r">إسم المعلم</th>
            <th class="r">الصف</th>
            <th class="r">المادة</th>
            <th><span>الطلاب</span></th>
            <th><span>الاثراءات</span></th>
            <th><span> الأسئلة</span></th>
            <th><span> المسارات المنشأة</span></th>
            <th><span> المسارات المنشورة</span></th>
            <th><span> المسارات المعتمدة</span></th>
            <th><span>الفصول</span></th>
            <th><span> الدروس المتزامنة</span></th>
            <th><span> الدروس غير المتزامنة</span></th>
            <th><span>إثراءات الدروس الافتراضية</span></th>
            <th><span>مواضيع النقاش</span></th>
            <th><span>الواجبات</span></th>
            <th><span>الواجبات المنشورة</span></th>
            <th><span>واجبات الحصص</span></th>
            <th><span>واجبات منشورة مصححة</span></th>
            <th><span>واجبات الحصص المصححة</span></th>
            <th><span> الإختبارات</span></th>
            <th><span>الإختبارات المنشورة</span></th>
            <th><span>إختبارات الحصص</span></th>
            <th><span>الأنشطة</span></th>
            <th><span>الأنشطة المنشورة</span></th>
            <th><span>أنشطة الحصص الاساسية</span></th>
        </tr>
        <tbody id="CUVgKWwm9aLRHRQB"></tbody>
    </table>
    <div class="ajax"></div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            date: new Date().toISOString().split("T")[0]
        }
    },
    created(){
        var g = this;
        var naqar_get = function(t){
        return new Promise((resolveOuter) => {
            $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
            var i111 = setInterval(function(){
                if($(".get-local-storage-value[name='"+t+"']").length){
                    clearInterval(i111)
                    resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
                }
            })
        });
        }
        naqar_get("teachersReportsHTML").then(function(data){
        data = {
            teachersReportsHTML: data
        }
        var html = data.teachersReportsHTML;
        const regex = /script/gi;
        var html = html.replaceAll(regex, 'nsc');
        $(".ajax").html(html);
        var teachers = [];
        var num = 1;

        naqar_get("teachers").then(function(teas){

            teas = {
                teachers: JSON.parse(teas)
            }

            $(".caaaa").each(function () {

                var html = $(this).html();
                var id = $("id", $(this)).html();

                var name = 'لم يتم التعرف';

                if (teas.teachers) {

                    teas.teachers.forEach(element => {
                        if (element.id == id) {
                            name = element.name
                        }
                    });

                }

                var g = $(this);

                if (id != undefined && name != undefined) {

                    var teacher = [];

                    teacher.push({
                        "number": num,
                        "name": name,
                        "id": id
                    });

                    num = num + 1;

                    $(".img-subject", g).each(function () {

                        var d = $(this).parent().parent();
                        var d2 = $(this).parent().parent().parent().parent();
                        var g = d;
                        var title = d.prev();
                        var title = $("h3", title).html();
                        var class_name = title.split("-")[1];
                        var class_name = class_name.replace(" الصف", "");
                        var subject = title.split("-")[3] + " <br> " + title.split("-")[2];
                        if (title.split("-")[4] != undefined) {
                            subject = title.split("-")[4] + " - " + subject;
                        }
                        var subjects = [];

                        subjects.push({
                            "class_name": class_name,
                            "subject": subject,
                            "i1": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(2) > ul > li:nth-child(1) > span", g).html(),
                            "i2": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(2) > ul > li:nth-child(2) > span", g).html(),
                            "i3": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(2) > ul > li:nth-child(3) > span", g).html(),
                            "i4": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(2) > ul > li:nth-child(4) > span", g).html(),
                            "i5": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(2) > ul > li:nth-child(5) > span", g).html(),
                            "i6": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(2) > ul > li:nth-child(6) > span", g).html(),
                            "i7": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(3) > ul > li:nth-child(1) > span", g).html(),
                            "i8": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(3) > ul > li:nth-child(2) > span", g).html(),
                            "i9": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(3) > ul > li:nth-child(3) > span", g).html(),
                            "i10": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(3) > ul > li:nth-child(4) > span", g).html(),
                            "i11": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(3) > ul > li:nth-child(5) > span", g).html(),
                            "i12": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(4) > ul > li:nth-child(1) > span", g).html(),
                            "i13": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(4) > ul > li:nth-child(2) > span", g).html(),
                            "i14": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(4) > ul > li:nth-child(3) > span", g).html(),
                            "i15": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(4) > ul > li:nth-child(5) > span", g).html(),
                            "i16": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(4) > ul > li:nth-child(6) > span", g).html(),
                            "i17": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(5) > ul > li:nth-child(2) > span", g).html(),
                            "i19": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(5) > ul > li:nth-child(3) > span", g).html(),
                            "i20": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(5) > ul > li:nth-child(4) > span", g).html(),
                            "i21": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(6) > ul > li:nth-child(1) > span", g).html(),
                            "i22": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(6) > ul > li:nth-child(6) > span", g).html(),
                            "i23": d2.find("#div_Block > div:nth-child("+ ((teacher.length == 0 ? 1 : teacher.length) * 2) +") > div:nth-child(6) > ul > li:nth-child(4) > span", g).html(),

                        });

                        teacher.push(subjects);

                    });

                    teachers.push(teacher);

                }

            });


            $.each(teachers, function (index, teacher) {

                if (index % 2 == 0) {
                    var color = "#f5f4f4";
                } else {
                    var color = "";
                }

                $("#CUVgKWwm9aLRHRQB").append(`
            <tr style='background-color: ` + color + `'>
                <td rowspan="` + (teacher.length) + `">` + teacher[0].number + `</td>
                <td rowspan="` + (teacher.length) + `">` + teacher[0].name + `</td>
            </tr>
            `);

                $.each(teacher, function (i, subject) {

                    if (i != 0) {

                        $("#CUVgKWwm9aLRHRQB").append(`
                    <tr class='tqq' style='background-color: ` + color + `'>
                        <td class='r'>` + subject[0].class_name + `</td>
                        <td class='r'>` + subject[0].subject.replace("undefined", '') + `</td>
                        <td>` + subject[0].i1 + `</td>
                        <td>` + subject[0].i2 + `</td>
                        <td>` + subject[0].i3 + `</td>
                        <td>` + subject[0].i4 + `</td>
                        <td>` + subject[0].i5 + `</td>
                        <td>` + subject[0].i6 + `</td>
                        <td>` + subject[0].i7 + `</td>
                        <td>` + subject[0].i8 + `</td>
                        <td>` + subject[0].i9 + `</td>
                        <td>` + subject[0].i10 + `</td>
                        <td>` + subject[0].i11 + `</td>
                        <td>` + subject[0].i12 + `</td>
                        <td>` + subject[0].i13 + `</td>
                        <td>` + subject[0].i14 + `</td>
                        <td>` + subject[0].i15 + `</td>
                        <td>` + subject[0].i16 + `</td>
                        <td>` + subject[0].i17 + `</td>
                        <td>` + subject[0].i19 + `</td>
                        <td>` + subject[0].i20 + `</td>
                        <td>` + subject[0].i21 + `</td>
                        <td>` + subject[0].i22 + `</td>
                        <td>` + subject[0].i23 + `</td>
                    </tr>
                    `);

                    }

                });




            });

            $("#CUVgKWwm9aLRHRQB").append(`
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><strong>مجموع بيانات التقرير</strong></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr-'>--</td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr-'>--</td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
            <td class='grr'></td>
        </tr>
        <style>
        .grr{
            font-style: bold
        }
        </style>
        `);

            setTimeout(() => {

                $(".grr").each(function () {
                    var count = 0

                    g = $(this)

                    $(".tqq").each(function () {
                        count = count + new Number($(this).find("td:nth-child(" + (g.index() - 1) + ")").text())
                    })

                    $(this).html(count)

                })

                window.print();

            }, 1000);



        })

    });

    }
}
</script>

<style>



        body {
            background-color: #fff;
            padding-top: 120px;
        }
        * {
            white-space: nowrap;
        }

        table,
        td,
        th {
            border: 1px solid black;
            padding: 3px;
            white-space: nowrap;
            border-top: none;
        }

        table {
            width: 99% !important;
            border-collapse: collapse;
            margin: 0px auto;
        }

        th.r {
            border-top: 1px solid black;
        }

        td:not(.r) {
            padding-right: 15px;
            padding-left: 10px;
        }

        th:not(.r) {
            position: relative;
        }

        th:not(.r) span {
            position: absolute;
            transform: rotate(-50deg);
            bottom: 15px;
            left: 0px;
            width: 100%;
            height: 100%;
            direction: ltr;
            border-top: 1px solid black;
            border-radius: 7px 0px 0px 0px;
        }

        nsc,
        .ajax,
        footer {
            display: none;
        }
</style>